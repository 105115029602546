<template>
  <div class="p-1">
    <div class="flex justify-between items-center flex-wrap mb-2">
      <h3 class="text-2xl">Períodos</h3>
      <a-button :to="{ name: 'create_period' }" v-if="!user.isStudent && !user.isLowAdmin" class="w-full md:w-auto">
        Crear período
      </a-button>
    </div>

    <a-table
      :columns="columns"
      :source="pagination.data"
      :loading="loading"
      :pagination="pagination"
      @paginate="search({ page: $event })"
      @delayed-filter:period="search({ page: 1, year_number: $event })"
      toolbar-column-filter
      @toolbar(refresh)="search({ page: 1 })">
      <template #td(actions)="{ item }">
        <a-dropdown>
          <template #menu>
            <a-dropdown-item :to="{ name: 'periods.show', params: { id: item.id } }">
              Ver detalles
            </a-dropdown-item>
            <template v-if="user.isAdmin">
              <a-dropdown-item @click="update(item.id, { enabled: !item.enabled })">
                <div class="flex justify-between w-full space-x-2 flex-grow">
                  <a-loader v-if="updating.includes(item.id)" />
                  {{item.enabled
                  ? updating.includes(item.id) ? 'Desactivando...' : 'Desactivar'
                  : updating.includes(item.id) ? 'Activando...' : 'Activar'
                  }}
                </div>
              </a-dropdown-item>
              <a-dropdown-item @click="setOpenInscriptions(item)">
                <div class="flex justify-between text-left w-full space-x-2 flex-grow">
                  {{ item.enabled_inscriptions | boolean('Desactivar', 'Activar') }} inscripciones
                </div>
              </a-dropdown-item>
              <a-dropdown-item :to="{ name: 'create_period', query: { update: item.id } }">
                Actualizar
              </a-dropdown-item>
              <a-dropdown-item @click="deletePeriod(item.id)">
                Eliminar
              </a-dropdown-item>
            </template>
          </template>
        </a-dropdown>
      </template>
    </a-table>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  data: () => ({
    query: {
      limit: 10,
      search: '',
      year_number: '',
      sort_by: 'year,desc',
      // with: 'artModel'
    },
    timeout: null,
    updating: []
  }),
  computed: {
    ...mapState({
      user: state => state.session.user,
      pagination: state => state.periods.pagination,
      loading: state => state.periods.loading
    }),
    columns() {
      return [
        { title: 'Período', key: 'period', mutate: ({ item }) => `${item.year}-${item.number}`, visible: true, filter: true, preventLocalFilter: true, display: true },
        { title: 'Número', key: 'number', visible: false, display: true },
        { title: 'Semanas', key: 'weeks_length', visible: false, display: true },
        { title: 'costo', key: '', visible: true, display: true, mutate: ({ item }) => `$${item.semester_price_e2 / 100}` },
        { title: 'Fecha de inicio', key: 'start_date', alignment: 'center', mutate: ({ item }) => this.$options.filters.date(item.start_date, 'DD-MM-YYYY'), visible: true, display: true },
        { title: 'Fecha final', key: 'end_date', alignment: 'right', mutate: ({ item }) => this.$options.filters.date(item.end_date, 'DD-MM-YYYY'), visible: true, display: true },
        { title: 'Primer corte', key: 'first_half_end_date', alignment: 'right', visible: false, display: true },
        { title: 'Segundo corte', key: 'second_half_end_date', alignment: 'right', visible: false, display: true },
        { title: 'Mínima nota aprobatoria', key: 'approval_minimum_note', alignment: 'right', visible: false, display: true },
        { title: 'Nota de alto rendimiento', key: 'high_performance_note', alignment: 'right', visible: false, display: true },
        { title: 'Nota de bajo rendimiento', key: 'low_performance_note', alignment: 'right', visible: false, display: true },
        { title: 'Director responsable', key: 'staff.director', alignment: 'right', visible: false, display: true },
        { title: 'Vicedirector responsable', key: 'staff.vice_director', alignment: 'right', visible: false, display: true },
        { title: 'Responsable de control de estudio', key: 'staff.study_control', alignment: 'right', visible: false, display: true },
        { title: 'Administrador responsable', key: 'staff.administrative_director', alignment: 'right', visible: false, display: true },
        { title: 'Responsable de firmas', key: 'staff.reports_signer', alignment: 'right', visible: false, display: true },
        { title: 'Inscripciones', key: 'enabled_inscriptions', alignment: 'right', mutate: ({ key }) => key ? 'Si' : 'No', visible: true, display: true },
        { title: '¿Activo?', key: 'enabled', alignment: 'center', mutate: ({ key }) => key ? 'Si' : 'No', visible: true, display: true },
        { title: 'Encuestas', key: 'polls', alignment: 'right', mutate: ({ key }) => key ? 'Si' : 'No', visible: false, display: true },
        { title: 'Entrega final', key: 'final_delivery', alignment: 'right', mutate: ({ key }) => key ? 'Si' : 'No', visible: true, display: true },
        { title: 'Acciones', slot: 'actions', alignment: 'right', visible: true, columnFilterable: false, display: this.user.isAdminGroup },
      ].filter($0 => $0.display)
    }
  },
  methods: {
    ...mapActions({
      fetchPeriods: 'periods/index',
      deletePeriod: 'periods/delete',
      openInscriptions: 'periods/openInscriptions'
    }),
    setOpenInscriptions(period) {
      this.$confirm({
        message: `¿Seguro que desea realizar esta acción?`,
        button: {
          no: 'No',
          yes: 'Si'
        },
        callback: confirm => {
          if (confirm) {
            this.openInscriptions({
              ...period,
              enabled_inscriptions: !period.enabled_inscriptions,
              enabled_preregisters: period.enabled_preregisters
            })
              .then(() => {
                this.search()
              })
          }
        }
      })
    },
    update(id, body) {
      this.updating.toggle(id)
      this.$repository.periods.update(id, body)
        .then(() => this.search())
        .finally(() => this.updating.toggle(id))
    },
    search(query = {}) {
      return this.fetchPeriods({ ...this.query, ...query })
    }
  },
  mounted() {
    this.search({ ...this.query, page: this.$route.query.page || 1 })
  }
}
</script>
